import React from 'react'

import Layout from '../components/layout'
import Seo from "../components/seo"
import LetsTalk from '../components/LetsTalk'

import forecastExampleImage from "../../static/images/forecast.png"
import accountExampleImage from "../../static/images/account.png"
import { DEMO_URL } from '../constants'
import { Link } from 'gatsby'
import { leftRightSection } from '../components/leftRightSection'

export default function IndexPage() {
  return (
    <Layout>
      <Seo title="Clove | The Modern Customer Success Platform" />

      {topSection()}

      <div className="bg-gray-50 relative py-8 md:py-24 lg:py-32 overflow-hidden">
        {leftRightSection(KNOW_SECTION)}
      </div>

      <div className="bg-white relative py-8 md:py-24 lg:py-32 overflow-hidden">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8">
          <div className={`text-center px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-3xl`}>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                <Link className="underline" to="/blog/customer-service-is-part-of-your-product/">
                  How much does Customer Success matter? 
                </Link>
              </h2>

              <p className="mt-8 text-lg text-gray-500">
                Unlike Sales, the actual dollar-value of Customer Success isn't self evident.    
              </p>

              <p className="mt-8 text-lg text-gray-500">
                Clove doesn't just help retain and expand customers. It helps you understand and report the impact of CS effort on revenue. Start tracking how much each CSM contributes to the bottom line.
              </p>

              <p className="mt-8 text-lg text-gray-500">
                <b>Start proving the value of Customer Success.</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 relative py-8 md:py-24 lg:py-32 overflow-hidden">
        {leftRightSection(CENTRALIZE_SECTION)}
      </div>

      <div className="bg-white relative py-8 md:py-24 lg:py-32 overflow-hidden">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8">
          <div className={`text-center px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-3xl`}>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Let Clove be your Customer Success experts.
              </h2>

              <p className="mt-8 text-lg text-gray-500">
                We totally get it, implementing a new Success solution can feel overwhelming. Luckily, we're
                experts on getting a modern CS process up an running quickly. 
              </p>

              <p className="mt-8 text-lg text-gray-500">
                We'll lead the charge on setting up your platform and integrating your tools. And we'll help put together a simple, strategic 
                process for you and your team. 
              </p>
            </div>
          </div>
        </div>
      </div>

      <LetsTalk />
    </Layout>
  )
}

function topSection() {
  return (
    <div className="py-10 bg-white sm:py-20 lg:overflow-hidden">
      <div className="mx-auto lg:px-8 max-w-7xl">
        <div className="mx-auto max-w-md px-4 sm:max-w-none sm:px-6 sm:text-center">
          <div>
            <h1 className="heading-hero">
              <span className="block">The Modern Customer Success Platform</span>
              <span className="mt-1 block bg-clip-text text-transparent bg-gradient-to-r from-brand-400 to-brand-700">
                Strategy meets Simplicity 
              </span>
            </h1>

            <p className="mt-8 text-primary sm:max-w-2xl mx-auto">
              Understand customers. Predict revenue. <b>Prove the value of CS.</b> 
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const CENTRALIZE_SECTION = {
  header: `Understand any customer in 30-60 seconds.`,
  body: [
    `A customer's story is more than just data. Understand their goals, thier struggles, their success plan, and how the product is delivering value.`,
    `Clove gets you up to speed on any customer. Quickly. Without reading through notes and emails. Like when you're about to get on a call. Or when a new CSM takes over an account.`
  ],
  image: accountExampleImage,
  position: 'right',
  imageAlt: `The back-end of Clove showing a customer organization detail page. Uploaded files are visible in the image.`
}

const KNOW_SECTION = {
  header: (
    <div>
      <span>Forecast customer revenue.&nbsp;</span>
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-brand-400 to-brand-600">Accurately.</span>
    </div>
  ),
  body: [
    `How many customers are at risk? Which customers will grow? How do you know?`,
    `Clove lets you easily predict customer risk and opportunity. So CSMs know where to prioritize their effort. All without maintaining complex health scores.`,
    ``
  ],
  image: forecastExampleImage,
  position: 'right',
  imageAlt: `A Clove Success Space showing videos, files, and action lists.`
}
